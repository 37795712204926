import api from '@/services/api'

export default {
  getCompanies() {
    return api.get('/companies/b2b')
  },

  getSettings() {
    return api.get('/settings')
  },

  getPaymentConditions() {
    return api.get('/payment-conditions/settings')
  },

  update(data) {
    return api.put('/settings', data)
  },

  updateCampaignDepartment(data) {
    return api.put(`/campaigns/department/${data._id}`, data)
  },

  getCampaignDepartmentMobwit(term = '') {
    return api.get(`/campaigns/department?origin=mobwit&term=${term}`)
  },

  getCampaignDepartmentB2b(term = '') {
    return api.get(`/campaigns/department?origin=b2b&term=${term}`)
  },

  createCampaignDepartment(data) {
    return api.post('/campaigns/department', data)
  },

  removeCampaignDepartment(id) {
    return api.delete(`/campaigns/department/${id}`)
  },

  updateCampaignProduct(data) {
    return api.put(`/campaigns/product/${data._id}`, data)
  },

  getCampaignProductMobwit(term = '') {
    return api.get(`/campaigns/product?origin=mobwit&term=${term}`)
  },

  getCampaignProductB2b(term = '') {
    return api.get(`/campaigns/product?origin=b2b&term=${term}`)
  },

  createCampaignProduct(data) {
    return api.post('/campaigns/product', data)
  },

  removeCampaignProduct(id) {
    return api.delete(`/campaigns/product/${id}`)
  },

  updateCoupon(data) {
    return api.put(`/coupons/${data._id}`, data)
  },

  getCoupons(term = '', page, onlyActive) {
    let params = `?term=${term}&page=${page}`;

    if (onlyActive) {
      params += `&onlyActive=true`
    }
    
    return api.get(`/coupons${params}`);
  },

  createCoupon(data) {
    return api.post('/coupons', data)
  },

  removeCoupon(id) {
    return api.delete(`/coupons/${id}`)
  },
}